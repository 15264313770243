<template>
    <div class="video_box">
        <div class="for_desktop" v-if="!props.isMobile">
            <video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="3840" height="1520">
                <source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/black_friday_large.mp4" type="video/mp4">
            </video>
        </div>
        <div v-else class="mb-3 for_mobile">
            <video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="1380" height="1920">
                <source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/black_friday_small.mp4" type="video/mp4">
            </video>
        </div>
		<NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})"	>
			<div class="text_box">
				<img src="~/assets/black_friday/banner_text.svg" width="320" height="284">
					<p class="mb-3">Be the First to Shine With <br>Deals You Don’t Want to Miss!</p>
					<div class="shop_now">

						Shop Now
					</div>
					
			</div>
		</NuxtLink>
    </div>
</template>
<script setup>
	const localePath = useLocalePath()
	const props = defineProps(['isMobile'])
</script>
  
<style scoped>
	.videodiv{
		filter: brightness(0.85);
	}
	.video_box{
		position: relative;
	}
  	video{
		max-width: 100%;
		height: auto;
		/* clip-path: inset(0); */
		/* clip-path: inset(1px 1px) !important; */
	}
	.text_box{
		position: absolute;
		top: 50%;
		right: 0;
		text-align: left;
		color: #ffffff;
		transform: translate(-50%, -50%);
	}
	.text_box h4 {
		font-size: 50px;
	}
	.text_box .shop_now{
		color: #ffffff;
		display: inline-block;
		border: 1px solid #ffff;
		font-weight: 600;
		margin: 0 auto;
		padding: 10px 25px;
		text-transform: uppercase;
		min-width: 200px;
		text-align: center;
		font-size: 14px;
		border-radius: 4px;
	}
	.text_box p {
		font-size: 18px;
		letter-spacing: 3px;
		text-transform: uppercase;
		color: #f5f5f5;
		text-shadow: 0px 0px 1.7px rgba(0, 0, 0, 0.25);
		margin-top: 0px;
	}

	.banner_btn {
		display: flex;
		justify-content: flex-start;
	}

	.banner_btn a {
		padding: 7px 20px;
		margin-right: 3%;
		color: #ffffff;
		border: 1px solid #ffffff;
		font-size: 14px;
		text-transform: uppercase;
	}

	@media (max-width:1250px) {
		.text_box p {
			font-size: 16px;
			letter-spacing: 2px;
		}
		.text_box img {
			max-width: 80%;
		}
	}
	@media (max-width:992px) {
		
		.text_box img {
			max-width: 60%;
		}
	}
	@media (max-width:768px) {
		
		.text_box img {
			max-width: 60%;
		}
	}
	
	@media (max-width:767px) {
		.video_box {
			position: relative;
			min-height: 60vh;
		}
		.text_box {
			text-align: left;
			top: 73%;
			width: auto;
			transform: translate(-50%, -50%);
			left: 38%;
			right: auto;
		}
		.text_box h4 {
			font-size: 24px;
			text-align: center;
		}
		.text_box p {
			font-size: 12px;
			letter-spacing: 1px;
		}
		.banner_btn {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.banner_btn a {
			margin: 5px;
			padding: 7px 20px;
			max-width: 300px;
			width: 100%;
		}
	}
	@media (max-width:512px) {
		
	}
</style>